<template>
  <v-row dense>
    <v-col cols="12">
      <v-data-table
        v-model="selection"
        :headers="headers"
        :items="arrayList"
        :value="arrayList"
        :items-per-page="20"
        item-key="pk"
        disable-filtering
        :loading="isLoading"
        loading-text="Loading data... Please wait"
        class="mb-5"
        :footer-props="pageOptions"
      >
        <template v-slot:[`item.name`]="props">
          <span>{{ props.item.name }}</span>
        </template>
        <template v-slot:[`item.net`]="props">
          <span>{{ props.item.net }}</span>
        </template>
        <template v-slot:[`item.x`]="props">
          <span>{{ props.item.x.toFixed(4) }}</span>
        </template>
        <template v-slot:[`item.y`]="props">
          <span>{{ props.item.y.toFixed(4) }}</span>
        </template>
        <template v-slot:[`item.min_spacing`]="props">
          <span>{{ props.item.min_spacing.toFixed(4) }}</span>
        </template>
        <template v-slot:[`item.layer`]="props">
          <span>{{ props.item.layer }}</span>
        </template>
        <template v-slot:[`item.type`]="props">
          <span>{{ props.item.type }}</span>
        </template>
        <template v-slot:[`item.enabled`]="props">
          {{
            !props.item.enabled
          }}
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>
<script>
import PageOptions from "@/mixins/PageOptions";
export default {
  name: "DataTableTestPoints",
  mixins: [PageOptions],
  props: {
    arrayList: {
      type: Array,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selection: [],
      headers: [
        {
          text: "Row",
          value: "index_number",
          width: "75",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: "Net",
          value: "net",
          width: "150",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: "Name",
          align: "start",
          value: "name",
          width: "120",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: "X Coord",
          align: "start",
          value: "x",
          width: "140",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: "Y Coord",
          align: "start",
          value: "y",
          width: "140",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: "Clearance",
          align: "start",
          value: "min_spacing",
          width: "140",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: "Side",
          align: "start",
          value: "layer_name",
          width: "100",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: "Type",
          align: "start",
          value: "type_name",
          width: "150",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: "Hole Size",
          align: "start",
          value: "hole_size",
          width: "105",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: "Receptacle Type",
          align: "center",
          value: "receptacle",
          width: "200",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: "Probe Tip Type",
          align: "center",
          value: "probe",
          width: "200",
          class: "primary--text body-2 font-weight-bold",
        },
        {
          text: "Do Not Populate",
          align: "center",
          value: "enabled",
          width: "150",
          class: "primary--text body-2 font-weight-bold",
        },
      ],
    };
  },
};
</script>
